/* Overrides */
/* textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=text]:focus,
[type=email].form-control:focus,
[type=password].form-control:focus,
[type=tel].form-control:focus,
[type=text].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #000000;
} */

.container {
	padding: 0;
	max-width: 97%;
	height: calc(100vh - 2vh);
	margin-top: 1rem;
	margin-right: 0.5rem;
	font-family: Poppins-Regular !important;
	overflow-y: auto !important;
}

.container .row {
	margin: 0;
}

.navbar, .navbar .container {
    padding: 0 !important;
}

.navbar .row {
    margin: 0 !important;
}

.dx-toolbar,.dx-datagrid-header-panel, .dx-pager {
	background-color: #f8f8f8
}

.dx-field-item-label-content, .dx-form-group-caption
{
	font-weight: bold;
}
/* Colours */
.b-black {
    border-color: #000000;
}

.b-black {
    border-color: #344281;
}

.b-pink-darker {
    border-color: #e3007d;
}

.bg-black {
    background-color: #000000;
}

.bg-dark-grey {
    background-color: #4a4a4a;
}

.bg-darker-grey {
    background-color: #2a2a2a;
}

.bg-green-gradient {
    background: #87d600;
    background: -moz-linear-gradient(left, #87d600 0%, #d0d600 100%);
    background: -webkit-linear-gradient(left, #87d600 0%, #d0d600 100%);
    background: linear-gradient(to right, #87d600 0%, #d0d600 100%);
}

.bg-light-grey {
    background-color: #f8f8f8;
}

.bg-pink-darker {
    background-color: #e3007d;
}

.bg-pink-gradient {
    background: #e3007d;
    background: -moz-linear-gradient(left, #e3007d 0%, #f300b4 99%);
    background: -webkit-linear-gradient(left, #e3007d 0%, #f300b4 99%);
    background: linear-gradient(to right, #e3007d 0%, #f300b4 99%);
}

.blue {
    color: #344281;
}

.dark-grey {
    color: #4a4a4a;
}

.grey {
	color: #959595;
}

.light-green {
    color: #9fba06;
}

.pink-darker {
    color: #e3007d;
}

.red {
    color: #ff0000;
}

.white {
    color: #ffffff;
}

/* Font Sizes */
.fs-10 {
    font-size: 10px;
}

.fs-20 {
    font-size: 20px;
}

.fs-30 {
    font-size: 30px;
}

.fs-40 {
    font-size: 40px;
}

.fs-50 {
    font-size: 50px;
}

/* Styles */
.align-items-middle {
    align-items: center;
    display: flex;
}

.border-bottom-2 {
    border-style: solid;
    border-width: 0 0 2px 0;
}

.border-none {
	border: none;
}

.pointer {
    cursor: pointer;
}

.shadow-highlight {
	box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1)!important;
}

.translucent-background {
	background-color: rgba(0, 0, 0, 0.3);
}

.greatNews {
	width:calc(100% -  40px) !important;
	height:50px !important;
	margin:20px;
	padding:15px;
	border-radius: 20px;
	background-color:#5CB85C;
	color:white;
}

.badNews {
	width:calc(100% -  40px) !important;
	height:50px !important;
	margin:20px;
	padding:15px;
	border-radius: 20px;
	background-color:#f77104;
	color:black;
}

/* Background Images */
.main-background-image {
	/* background-image: url("/assets/top-section-bg.jpg"); */
	background-repeat: no-repeat;
    background-size: cover;
}

.white-background-image {
	background-attachment: fixed;
	/* background-image: url("/assets/white-bg.jpg"); */
	background-repeat: no-repeat;
    background-size: cover;
}

/* Fonts */
/* @font-face {
	font-family: "Montserrat Black";
	src: url(assets/Montserrat-Black.ttf) format("truetype");
} */
@font-face {
	font-family: Poppins-Regular;
	src: url('/assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
	font-family: Poppins-Bold;
	src: url('/assets/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
	font-family: Poppins-Medium;
	src: url('/assets/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
	font-family: Montserrat-Bold;
	src: url('/assets/fonts/montserrat/Montserrat-Bold.ttf');
}

/* Animations */
.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.jump {
    animation: jump 1s infinite;
    animation-direction: alternate;
    position: relative;
	transition: .5s all;
}

@keyframes jump {
    0% {
        bottom: 10px;
    }
    100% {
        bottom: -10px;
    }
}

@media not screen {
	.dx-drawer-wrapper { height: auto !important; overflow: visible !important; }
	.dx-drawer-content { height: auto !important; overflow: visible !important; }
}
